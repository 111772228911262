import { useMemo } from "react";
import { Platform, useWindowDimensions, PixelRatio } from "react-native";
import ResponsiveBreakpoints from "../constants/ResponsiveBreakpoints";
import useScreenOrientation from "./useScreenOrientation";

export enum ScreenTypeEnum {
  PHONE = "phone",
  TABLET = "tablet",
  DESKTOP = "desktop"
}

export enum ScreenOrientationTypeEnum {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape"
}

const getDeviceType = (size: number) => {
  if (size < ResponsiveBreakpoints.PHONE_TABLET_BREAKPOINT)
    return ScreenTypeEnum.PHONE;
  else if (size < ResponsiveBreakpoints.TABLET_DESKTOP_BREAKPOINT)
    return ScreenTypeEnum.TABLET;
  else return ScreenTypeEnum.DESKTOP;
};

const useScreenType = () => {
  const orientation = useScreenOrientation();
  let { width, height } = useWindowDimensions();

  // On web, we want to use the entire window screen.
  // Handling window resizing with keyboard is quite problematic on web.
  if (Platform.OS === "web") {
    width = window.screen.width;
    height = window.screen.height;
  }

  const type = useMemo(() => {
    const screenDensity = PixelRatio.get();
    const widthPixels = width * screenDensity;
    const heightPixels = height * screenDensity;

    // https://stackoverflow.com/questions/56451288/get-screen-dpi-in-react-native
    // dpi value is not exact, we need to get this value from the device if possible.
    const dpi = 160 * screenDensity;
    const inches = Math.hypot(widthPixels, heightPixels) / dpi;
    const type = getDeviceType(inches);

    return type;
  }, [width, height]);

  return {
    type,
    orientation
  };
};

export default useScreenType;
