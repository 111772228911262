import { NativeStackHeaderProps } from "@react-navigation/native-stack";

import YourCareTeamScreen from "../screens/dashboard/YourCareTeamScreen";
import BookAppointmentScreen from "../screens/dashboard/BookAppointmentScreen";
import ChatScreen from "../screens/dashboard/ChatScreen";
import MyAppointmentsScreen from "../screens/dashboard/MyAppointmentsScreen";
import RateAppointmentScreen from "../screens/dashboard/RateAppointment/RateAppointmentScreen";

import MyGoalsScreen from "../screens/dashboard/MyGoalsScreen";
import EditGoalScreen from "../screens/dashboard/EditGoalScreen";
import AddGoalScreen from "../screens/dashboard/AddGoalScreen";
import GoalDetailsScreen from "../screens/dashboard/GoalDetailsScreen";
import AddGoalCategoryScreen from "../screens/dashboard/AddGoalCategoryScreen";
import NavigationBar from "../components/ui/navigation/NavigationBar";
import { ScreenTypeEnum } from "../hooks/useScreenType";
import { StackActions } from "@react-navigation/native";
import { useLayoutEffect } from "react";
import LocalizedStrings from "../helpers/LocalizedStrings";

interface HeaderProps extends NativeStackHeaderProps {
  rounded?: boolean;
  hideMenuToggleOnTablet?: boolean;
  hideBackButton?: boolean;
}

export const Header = (props: HeaderProps) => <NavigationBar {...props} />;

export const ScreenOrientation = (type: ScreenTypeEnum) =>
  type === ScreenTypeEnum.TABLET ? "all" : "portrait";

export type YourCareTeamNavigationType = {
  YourCareTeam: undefined;
  BookAppointment: { isReschedule: boolean; user_id: string };
  MyAppointments: undefined;
  Chat: undefined;
  VideoCall: { code: string };
  RateAppointment: undefined;
};

export type GoalsNavigationType = {
  MyGoals: undefined;
  EditGoal: undefined;
  GoalDetails: undefined;
  AddGoalCategory: undefined;
  AddGoal: undefined;
};

export const useHideTabBar = (navigation, skip) => {
  useLayoutEffect(() => {
    if (skip) return;
    const tabBar = navigation.getParent();
    if (tabBar === undefined) return;

    tabBar.setOptions({
      tabBarStyle: { display: "none" }
    });

    return () => {
      tabBar.setOptions({
        tabBarStyle: { display: "flex" }
      });
    };
  }, []);
};

export const YourCareTeamNavigationFlow = (Stack) => [
  <Stack.Screen
    key="YourCareTeam"
    name="YourCareTeam"
    component={YourCareTeamScreen}
    options={{ title: LocalizedStrings.screens.yourCareTeam.title }}
  />,
  <Stack.Screen
    key="BookAppointment"
    name="BookAppointment"
    component={BookAppointmentScreen}
    options={{ title: LocalizedStrings.screens.bookAppointment.title }}
  />,
  <Stack.Screen
    key="MyAppointments"
    name="MyAppointments"
    component={MyAppointmentsScreen}
    options={{ title: LocalizedStrings.screens.myAppointments.title }}
  />,
  <Stack.Screen key="Chat" name="Chat" component={ChatScreen} />,
  <Stack.Screen
    key="RateAppointment"
    name="RateAppointment"
    component={RateAppointmentScreen}
    options={{ title: LocalizedStrings.screens.rateAppointment.title }}
  />
];

export const GoalsNavigationFlow = (Stack) => [
  <Stack.Screen
    key="MyGoals"
    name="MyGoals"
    component={MyGoalsScreen}
    options={{ title: LocalizedStrings.screens.goals.title }}
  />,
  <Stack.Screen
    key="GoalDetails"
    name="GoalDetails"
    component={GoalDetailsScreen}
    options={{ title: "Goal Title" }}
  />,
  <Stack.Screen
    key="EditGoal"
    name="EditGoal"
    component={EditGoalScreen}
    options={{ title: LocalizedStrings.screens.goals.editGoal }}
  />,
  <Stack.Screen
    key="AddGoalCategory"
    name="AddGoalCategory"
    component={AddGoalCategoryScreen}
    options={{ title: LocalizedStrings.screens.goals.addNewGoal }}
  />,
  <Stack.Screen
    key="AddGoal"
    name="AddGoal"
    component={AddGoalScreen}
    options={{ title: LocalizedStrings.screens.goals.addNewGoal }}
  />
];

export const resetTabStacks = (navigation) => {
  const state = navigation.getState();

  state.routes.forEach((route) => {
    if (route.state?.index > 0) {
      navigation.dispatch(StackActions.popToTop());
    }
  });
};
