import { createNativeStackNavigator } from "@react-navigation/native-stack";

import DeviceTypeEnum from "common/enums/DeviceTypeEnum";

import MyReadingsScreen from "../screens/readings/MyReadingsScreen";
import ReadingsDetailsScreen from "../screens/readings/ReadingsDetailsScreen";
import ReadingsHistoryScreen from "../screens/readings/ReadingsHistoryScreen";
import useScreenType from "../hooks/useScreenType";
import { Header, ScreenOrientation } from "./NavigationUtils";
import ChartFullModal from "../screens/readings/ChartFullModal";
import { DeviceType_url_encode } from "common/helpers/helpers";
import VideoCallComponent from "../components/VideoCallComponent";
import LocalizedStrings from "../helpers/LocalizedStrings";
import USPSTrackingComponent from "../components/USPSTrackingComponent";

export type ReadingsStackParamList = {
  MyReadings: undefined;
  BloodReadingDetails: { readingType: string };
  GlucoseReadingDetails: { readingType: string };
  ReadingsHistory: { readingType: string; days: number };
  ChartFullModal: { readingType: string; days: number };

  USPSTracking: { trackingNumber: string };
  VideoCall: { code: string };
};

const Stack = createNativeStackNavigator<ReadingsStackParamList>();
const ReadingsStackNavigator = () => {
  const { type } = useScreenType();

  return (
    <Stack.Navigator
      initialRouteName="MyReadings"
      screenOptions={{
        header: Header,
        orientation: ScreenOrientation(type)
      }}
    >
      <Stack.Group>
        <Stack.Screen
          name="MyReadings"
          component={MyReadingsScreen}
          options={{ title: LocalizedStrings.screens.myReadings.title }}
        />
        <Stack.Screen
          name="BloodReadingDetails"
          component={ReadingsDetailsScreen}
          options={{ title: LocalizedStrings.common.bloodPressure }}
          initialParams={{
            readingType: DeviceType_url_encode(DeviceTypeEnum.BLOOD_PRESSURE)
          }}
        />
        <Stack.Screen
          name="GlucoseReadingDetails"
          component={ReadingsDetailsScreen}
          options={{ title: LocalizedStrings.common.bloodSugar }}
          initialParams={{
            readingType: DeviceType_url_encode(DeviceTypeEnum.GLUCOSE_CATEGORY)
          }}
        />
        <Stack.Screen
          name="ReadingsHistory"
          component={ReadingsHistoryScreen}
          options={{ title: LocalizedStrings.screens.readingsHistory.title }}
        />

        <Stack.Screen
          name="VideoCall"
          component={VideoCallComponent}
          options={({ navigation }) => {
            return {
              detachPreviousScreen: !navigation.isFocused(),
              orientation: "all",
              headerShown: false,
              title: LocalizedStrings.screens.videoCall.title
            };
          }}
        />
        <Stack.Screen
          name="USPSTracking"
          component={USPSTrackingComponent}
          options={{
            headerShown: false
          }}
        />
      </Stack.Group>
      <Stack.Group
        screenOptions={{
          presentation: "fullScreenModal",
          statusBarHidden: true
        }}
      >
        <Stack.Screen
          name="ChartFullModal"
          component={ChartFullModal}
          options={{
            orientation: "all",
            headerShown: false
          }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default ReadingsStackNavigator;
