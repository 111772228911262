import { useColorScheme } from "react-native";
import { useSelector } from "react-redux";

import { RootState } from "common/redux";
import { setNativeDarkThemeEnabled } from "common/nativemodules/DarkModeModule";
import { DISABLE_DARK_THEME } from "common/redux/SettingsSlice";

const useGetCurrentColorScheme = () => {
  const defaultColorScheme = useColorScheme();

  const { darkThemeEnabled, useOSThemeEnabled } = useSelector(
    (state: RootState) => state.settings
  );

  if (DISABLE_DARK_THEME) return "light";

  const settingsMode = darkThemeEnabled ? "dark" : "light";
  const mode = useOSThemeEnabled ? defaultColorScheme : settingsMode;

  setNativeDarkThemeEnabled(mode === "dark");

  return mode;
};

export default useGetCurrentColorScheme;
