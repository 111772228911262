import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import useScreenType from "../../hooks/useScreenType";
import {
  Header,
  ScreenOrientation,
  YourCareTeamNavigationFlow,
  YourCareTeamNavigationType
} from "../NavigationUtils";

const Stack = createNativeStackNavigator<YourCareTeamNavigationType>();
const YourCareTeamStackNavigator = ({ navigation, route }) => {
  const { type } = useScreenType();

  return (
    <Stack.Navigator
      initialRouteName="YourCareTeam"
      screenOptions={{
        header: Header,
        orientation: ScreenOrientation(type)
      }}
    >
      {YourCareTeamNavigationFlow(Stack)}
    </Stack.Navigator>
  );
};

export default YourCareTeamStackNavigator;
