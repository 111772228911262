import { useEffect, useState } from "react";
import { isChrome } from "react-device-detect";

import { ScreenOrientationTypeEnum } from "./useScreenType";

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState<OrientationType>(
    screen?.orientation?.type
  );

  const updateOrientation = (event) => {
    const type = event.target.type;
    setOrientation(type);
  };

  const onOrientationChange = () => {
    if (window.innerWidth > window.innerHeight) {
      setOrientation("landscape-primary");
    } else {
      setOrientation("portrait-primary");
    }
  };

  useEffect(() => {
    onOrientationChange();
  }, []);

  useEffect(() => {
    if (!isChrome) {
      window.addEventListener("orientationchange", onOrientationChange);
      window.visualViewport.addEventListener("resize", onOrientationChange);
    }

    screen?.orientation?.addEventListener("change", updateOrientation);

    return () => {
      if (!isChrome) {
        window.visualViewport.removeEventListener(
          "resize",
          onOrientationChange
        );
        screen?.orientation?.removeEventListener(
          "orientationchange",
          onOrientationChange
        );
      }
      screen?.orientation?.removeEventListener("change", updateOrientation);
    };
  }, [screen?.orientation]);

  if (
    orientation === "landscape-primary" ||
    orientation === "landscape-secondary"
  )
    return ScreenOrientationTypeEnum.LANDSCAPE;
  else return ScreenOrientationTypeEnum.PORTRAIT;
};

export default useScreenOrientation;
