import { View, ViewStyle, Platform } from "react-native";
import {
  BottomTabBarProps,
  createBottomTabNavigator
} from "@react-navigation/bottom-tabs";
import { makeStyles, useTheme } from "@rneui/themed";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";
import { useNetInfo } from "@react-native-community/netinfo";
import Feather from "react-native-vector-icons/Feather";

import DashboardStackNavigator from "./DashboardStackNavigator";
import MyAccountStackNavigator from "./MyAccountStackNavigator";

import Text from "../components/ui/Text";
import ReadingsStackNavigator from "./ReadingsStackNavigator";
import { resetTabStacks } from "./NavigationUtils";
import TouchableOpacity from "../components/ui/TouchableOpacity";
import LocalizedStrings from "../helpers/LocalizedStrings";

export type TabsParamList = {
  Home: undefined;
  Readings: undefined;
  Account: undefined;
};

const Tab = createBottomTabNavigator<TabsParamList>();

const TabItem = ({ routeName, focused, navigation }) => {
  const { theme } = useTheme();
  const insets = useSafeAreaInsets();
  const styles = useStyles(insets);

  let iconName = "";
  let tabName = "";
  if (routeName === "Home") {
    iconName = "home";
    tabName = LocalizedStrings.screens.tab.home;
  } else if (routeName === "Readings") {
    iconName = "bar-chart-2";
    tabName = LocalizedStrings.screens.tab.readings;
  } else if (routeName === "Account") {
    iconName = "user";
    tabName = LocalizedStrings.screens.tab.account;
  }

  const handlePress = () => {
    resetTabStacks(navigation);
    navigation.navigate(routeName);
  };
  return (
    <TouchableOpacity
      accessibilityLabel={routeName}
      style={[styles.tabBarItem, !focused && { borderColor: "transparent" }]}
      onPress={handlePress}
    >
      <Feather name={iconName} size={24} color={theme.colors.white} />
      <Text capsSmall style={styles.tabBarItemTitle}>
        {tabName}
      </Text>
    </TouchableOpacity>
  );
};

const TabBar = ({
  state,
  descriptors,
  navigation,
  insets
}: BottomTabBarProps) => {
  const focusedOptions = descriptors[state.routes[state.index].key].options;
  const tabBarStyle = focusedOptions?.tabBarStyle as ViewStyle;

  const styles = useStyles(insets);
  const netInfo = useNetInfo();

  if (netInfo.isInternetReachable === false) return null;
  if (tabBarStyle?.display === "none") return null;

  const { routeNames, index } = state;

  return (
    <View style={[styles.tabBarBackground, tabBarStyle]}>
      <View style={styles.tabBar}>
        {routeNames.map((routeName, i) => {
          return (
            <TabItem
              key={routeName}
              routeName={routeName}
              focused={index === i}
              navigation={navigation}
            />
          );
        })}
      </View>
    </View>
  );
};

function TabNavigator() {
  return (
    <Tab.Navigator
      screenOptions={{ headerShown: false }}
      tabBar={(props) => <TabBar {...props} />}
    >
      <Tab.Screen name="Home" component={DashboardStackNavigator} />
      <Tab.Screen name="Readings" component={ReadingsStackNavigator} />
      <Tab.Screen name="Account" component={MyAccountStackNavigator} />
    </Tab.Navigator>
  );
}

const TABBAR_HEIGHT = 70;
const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  const bottomInsets = insets.bottom + (Platform.OS === "android" ? 10 : 0);
  const marginBottom = Math.max(bottomInsets, 10);

  return {
    tabBarBackground: {
      backgroundColor: theme.colors.background,
      marginBottom
    },
    tabBar: {
      height: TABBAR_HEIGHT,
      marginLeft: 20,
      marginRight: 20,
      borderRadius: TABBAR_HEIGHT / 2,
      backgroundColor: theme.colors.darkBlue,
      paddingBottom: 5,
      marginHorizontal: 10,
      paddingHorizontal: 5,
      flexDirection: "row"
    },
    tabBarItem: {
      flex: 1,
      height: TABBAR_HEIGHT - 10,
      borderRadius: (TABBAR_HEIGHT - 10) / 2,
      borderWidth: 2,
      borderColor: theme.colors.white,
      marginVertical: 5,
      alignItems: "center",
      justifyContent: "center"
    },
    tabBarItemTitle: {
      color: theme.colors.white
    }
  };
});

export default TabNavigator;
