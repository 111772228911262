// Author: https://github.com/straxico/use-detect-keyboard-open/blob/main/src/index.js
// Original lib does not work properly on iOS: https://github.com/straxico/use-detect-keyboard-open/issues/3

import { useEffect, useState } from "react";
import { isChrome, isSafari } from "react-device-detect";

import useScreenOrientation from "./useScreenOrientation";
import { ScreenOrientationTypeEnum } from "./useScreenType";

const minKeyboardHeight = 300;

const virtualKeyboardAPISupported = "virtualKeyboard" in navigator;
if (virtualKeyboardAPISupported) {
  navigator["virtualKeyboard"].overlaysContent = true;
}

const useKeyboard = () => {
  const screenOrientation = useScreenOrientation();

  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);
  const [isCurrentScreenOrientation, setCurrentScreenOrientation] =
    useState<ScreenOrientationTypeEnum>(screenOrientation);

  useEffect(() => {
    if (virtualKeyboardAPISupported) return;
    const onResizeWindow = () => {
      let height = window.screen.height;
      if (
        !isChrome &&
        isSafari &&
        screenOrientation === ScreenOrientationTypeEnum.LANDSCAPE
      ) {
        height = window.screen.width;
      }

      const newState =
        height - minKeyboardHeight > window.visualViewport.height;

      if (isCurrentScreenOrientation === screenOrientation) {
        setIsKeyboardOpen(newState);
      } else setCurrentScreenOrientation(screenOrientation);
    };

    if (window?.visualViewport) {
      window.visualViewport.addEventListener("resize", onResizeWindow);
    }

    return () => {
      if (window?.visualViewport) {
        window.visualViewport.removeEventListener("resize", onResizeWindow);
      }
    };
  }, [isCurrentScreenOrientation, screenOrientation]);

  useEffect(() => {
    if (!virtualKeyboardAPISupported) return;

    const { height } = navigator["virtualKeyboard"].boundingRect;
    setIsKeyboardOpen(height > 100);

    const onGeometryChange = (event) => {
      const { height } = event.target.boundingRect;
      setIsKeyboardOpen(height > 100);
    };

    navigator["virtualKeyboard"].addEventListener(
      "geometrychange",
      onGeometryChange
    );

    return () =>
      navigator["virtualKeyboard"].removeEventListener(
        "geometrychange",
        onGeometryChange
      );
  }, []);

  return isKeyboardOpen;
};

export default useKeyboard;
