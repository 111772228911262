import { Image, Platform, useWindowDimensions } from "react-native";
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
  createDrawerNavigator
} from "@react-navigation/drawer";
import Feather from "react-native-vector-icons/Feather";
import { useNetInfo } from "@react-native-community/netinfo";
import { makeStyles, useTheme } from "@rneui/themed";

import { copilotIQLogo } from "../../assets/common";
import DashboardStackNavigator from "../DashboardStackNavigator";
import MyAccountStackNavigator from "../MyAccountStackNavigator";
import useScreenType, {
  ScreenOrientationTypeEnum
} from "../../hooks/useScreenType";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import ReadingsStackNavigator from "../ReadingsStackNavigator";
import YourCareTeamStackNavigator from "./YourCareTeamStackNavigator";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
import { resetTabStacks } from "../NavigationUtils";
import ResponsiveBreakpoints from "../../constants/ResponsiveBreakpoints";

import { FULL_SCREEN_ROUTES } from "../Router";
import LocalizedStrings from "../../helpers/LocalizedStrings";

export type DrawerParamList = {
  Home: undefined;
  Readings: undefined;
  "Your Care Team": undefined;
  Account: undefined;
  YourVisits: undefined;
};

const Drawer = createDrawerNavigator<DrawerParamList>();

function CustomDrawerContent(props: DrawerContentComponentProps) {
  const styles = useStyles();
  const { theme } = useTheme();

  return (
    <DrawerContentScrollView {...props}>
      <Image
        style={styles.copilotLogo}
        source={copilotIQLogo}
        tintColor={theme.colors.white}
      />
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  );
}

const screenListeners = ({ navigation }) => {
  return {
    drawerItemPress: () => {
      resetTabStacks(navigation);
    }
  };
};

const DrawerNavigator = () => {
  const { theme } = useTheme();
  const { orientation } = useScreenType();
  const textStyles = useTextStyles();
  const { width } = useWindowDimensions();
  const netInfo = useNetInfo();

  return (
    <Drawer.Navigator
      initialRouteName="Home"
      screenListeners={screenListeners}
      // @ts-ignore
      screenOptions={({ route }) => {
        const routeName = getFocusedRouteNameFromRoute(route);

        const disableDrawerType =
          FULL_SCREEN_ROUTES.includes(routeName) && Platform.OS === "android";
        const defaultDrawerType = Platform.OS === "ios" ? "slide" : "front";

        let drawerType = defaultDrawerType;
        if (disableDrawerType) drawerType = defaultDrawerType;
        else if (
          orientation === ScreenOrientationTypeEnum.LANDSCAPE ||
          width >
            ResponsiveBreakpoints.SIDEBAR_DRAWER_WIDTH_PERMANENT_BREAKPOINT ||
          // Drawer non permanent does not work on web correctly.
          Platform.OS === "web"
        )
          drawerType = "permanent";

        return {
          headerShown: false,
          drawerType,
          overlayColor: "transparent",
          drawerStyle: {
            display:
              FULL_SCREEN_ROUTES.includes(routeName) ||
              netInfo.isInternetReachable === false
                ? "none"
                : "flex",
            backgroundColor: theme.colors.darkBlue
          },

          drawerActiveTintColor: theme.colors.white,
          drawerInactiveTintColor: theme.colors.white
        };
      }}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen
        name="Home"
        component={DashboardStackNavigator}
        options={{
          drawerLabel: LocalizedStrings.screens.tab.home,
          title: LocalizedStrings.screens.tab.home,
          drawerLabelStyle: textStyles.body,
          drawerIcon: ({ size, color }) => (
            <Feather size={size} color={color} name="home" />
          )
        }}
      />
      <Drawer.Screen
        name="Readings"
        component={ReadingsStackNavigator}
        options={{
          drawerLabel: LocalizedStrings.screens.tab.readings,
          title: LocalizedStrings.screens.tab.readings,
          drawerLabelStyle: textStyles.body,
          drawerIcon: ({ size, color }) => (
            <Feather size={size} color={color} name="bar-chart-2" />
          )
        }}
      />

      {/*
      To be implemented in the near future
      <Drawer.Screen
        name="YourVisits"
        component={YourVisitsStackNavigator}
        options={{
          drawerLabel: 'Your Visits',
          title: 'Your Visits',
          drawerLabelStyle: textStyles.body,
          drawerIcon: ({size, color}) => (
            <Feather size={size} color={color} name="bar-chart-2" />
          ),
        }}
      />*/}
      {/* <Drawer.Screen
        name="Appointments"
        component={MyAppointmentsStackNavigator}
        options={{
          drawerLabel: 'Appointments',
          title: 'Appointments',
          drawerLabelStyle: textStyles.body,
          drawerIcon: ({size, color}) => (
            <Feather size={size} color={color} name="calendar" />
          ),
        }}
      /> */}
      {/* <Drawer.Screen
        name="My Goals"
        component={MyGoalsStackNavigator}
        options={{
          drawerLabel: 'My Goals',
          title: 'My Goals',
          drawerLabelStyle: textStyles.body,
          drawerIcon: ({size, color}) => (
            <Feather size={size} color={color} name="award" />
          ),
        }}
      /> */}
      <Drawer.Screen
        name="Your Care Team"
        component={YourCareTeamStackNavigator}
        options={{
          drawerLabel: LocalizedStrings.screens.yourCareTeam.title,
          title: LocalizedStrings.screens.yourCareTeam.title,
          drawerLabelStyle: textStyles.body,
          drawerIcon: ({ size, color }) => (
            <Feather size={size} color={color} name="users" />
          )
        }}
      />

      <Drawer.Screen
        name="Account"
        component={MyAccountStackNavigator}
        options={{
          drawerLabel: LocalizedStrings.screens.tab.account,
          title: LocalizedStrings.screens.tab.account,
          drawerLabelStyle: textStyles.body,
          drawerIcon: ({ size, color }) => (
            <Feather size={size} color={color} name="user" />
          )
        }}
      />
    </Drawer.Navigator>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    copilotLogo: {
      width: 154,
      height: 50,
      marginLeft: 10,
      marginVertical: 10
    }
  };
});

export default DrawerNavigator;
